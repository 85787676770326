<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="text-center">French Section</h5>
      <div class="row">
        <div class="col-md-12 col-sm-12">
          <ValidationProvider
            vid="fr.parent_id"
            rules="required"
            name="The Parent"
            v-slot="{ passed, failed,errors }">
            <fg-select
              name="parent_id"
              size="large"
              filterable
              clearable
              placeholder="Parent"
              :error="failed ? errors[0]: null"
              :input-classes="'select-default'"
              :label="'Parent'"
              :list="builderData.pageList"
              :listItemLabel="'label'"
              :listItemValue="'id'"
              v-model="formData.parent_id">
            </fg-select>
          </ValidationProvider>

          <ValidationProvider
            vid="fr.page_name"
            rules="required"
            name="The Page Name"
            v-slot="{ passed, failed,errors }">
            <fg-input type="text"
                      :error="failed ? errors[0]: null"
                      label="Page Name"
                      name="page_name"
                      fou
                      v-model="formData.page_name">
            </fg-input>
          </ValidationProvider>


          <ValidationProvider
            vid="fr.redirection_type"
            rules="required"
            name="The Redirection Type"
            v-slot="{ passed, failed,errors }">
            <fg-select
              name="redirection_type"
              size="large"
              filterable
              clearable
              placeholder="Redirection Type"
              :error="failed ? errors[0]: null"
              :input-classes="'select-default'"
              :label="'Redirection Type'"
              :list="builderData.redirectionTypes"
              :listItemLabel="'label'"
              :listItemValue="'value'"
              v-model="formData.redirection_type">
            </fg-select>
          </ValidationProvider>

          <ValidationProvider
            vid="fr.redirection_url"
            rules="required"
            name="The Redirection Url"
            v-slot="{ passed, failed,errors }">
            <fg-input type="text"
                      :error="failed ? errors[0]: null"
                      label="Redirection Url"
                      name="redirection_url"
                      fou
                      v-model="formData.redirection_url">
            </fg-input>
          </ValidationProvider>

          <ValidationProvider
            vid="fr.redirection_target"
            rules="required"
            name="The Redirection Target"
            v-slot="{ passed, failed,errors }">
            <fg-select
              name="redirection_target"
              size="large"
              filterable
              clearable
              placeholder="Redirection Type"
              :error="failed ? errors[0]: null"
              :input-classes="'select-default'"
              :label="'Redirection Target'"
              :list="builderData.redirectionTargets"
              :listItemLabel="'label'"
              :listItemValue="'value'"
              v-model="formData.redirection_target">
            </fg-select>
          </ValidationProvider>
          <div class="form-group pt-4">
            <label>Is Active</label>&nbsp;
            <l-switch v-model="formData.is_active">
              <i class="fa fa-check" slot="on"></i>
              <i class="fa fa-times" slot="off"></i>
            </l-switch>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import {extend} from "vee-validate";
import {email, required} from "vee-validate/dist/rules";
import {Option, Select, Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {PrimeUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'

extend("required", required);
extend("email", email);

export default {
  components: {
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    PrimeUploader,
    'editor': Editor
  },
  props: {
    lang: {
      type: String,
      default: 'fr'
    },
    editMode: {
      type: Boolean,
      default: false
    },
    builderData: {},
    formData: {
      type: Object,
      default() {
        return {
          page_name: "",
          parent_id: "",
          redirection_type: "",
          redirection_url: "",
          redirection_target: "",
          is_active: true,
        }
      }
    },
  },
  data() {
    return {
      editorConfig: this.getEditorConfig(),
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
    };
  },
  mounted() {

  },
  methods: {
    validate() {
      return this.$refs.form.validate().then(res => {
        if (res) {
          this.$emit('submit-step', this.formData, this.lang)
        } else {
          this.$emit('on-validated', res)
        }
        return res
      });
    },
  }
}
</script>
<style>
</style>
